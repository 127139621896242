import React, { useState }  from 'react'
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography"
import { Controller } from "react-hook-form";
const Input = ({control,name, label,...props}) => {
  return (
    <>
      <Typography component={"label"} variant={"body1"} fontWeight={"bold"}>{label}</Typography>
      <Controller
        name={name}
        control={control}
        render={({
          field,
          fieldState: {error },
          formState
                 }) =><TextField  error={error}  helperText={error?.message} fullWidth size="small" {...field} {...props}  sx={{mb: 2}}/>}
      />
    </>
  );
};
export default Input;
