import {Typography, Button} from '@mui/material'
import Link from '@mui/material/Link'
import * as React from 'react'
import Input from './Input'
import Checkbox from "./Checkbox";
import Form from './Form'
import Section from '../main/Section'
import * as yup from "yup";
const schema = yup.object({
  name: yup.string().required(),
  sender: yup.string().email(),
  phonenumber:yup.string().required(),
  message:yup.string(),
  datenschutz:yup.bool().required()
}).required();
const Contact = ({url}) => {
  return (
    <Section>
      <Typography sx={{mb: 2}} variant={"h4"} component={"h2"}>Kontakt</Typography>
      <Typography sx={{pb: 2}}> Schreiben Sie uns Ihre Wünnsche und Ziele und wir helfen Ihnen bei der Umsezung oder
        vereinbaren Sie mit uns einnen unverbindlichen kennenlernen Termin.<br/>
        Wir freuen uns auf Sie.</Typography>
      <Form validation={schema} url={url}>
        <Input required name={"name"} label={"Name"} />
        <Input name={"sender"} label={"E-Mail Adresse"} />
        <Input required name={"phonenumber"} label={"Telefonnummer"} />
        <Input name={"message"} label={"Anliegen"} multiline minRows={"4"} />
        <Checkbox required name={"datenschutz"}>Ich stimme den <Link href={'/datenschutz'} target="_blank" rel="noopener noreferrer">Datenschutzbedingungen</Link> zu.</Checkbox>
        <br/>
        <Button variant={"outlined"} color={"error"} type={"submit"} sx={{mt: 1}}>{'>> Absenden >>>'}</Button>
      </Form>
    </Section>
  )
}

export default Contact
