import React, {useState} from 'react'
import Switch from "@mui/material/Switch";
import {Controller} from "react-hook-form";
import Typography from "@mui/material/Typography";

const Checkbox = ({control,children, name, ...props}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
                   field,
                   fieldState: {error},
                 }) => <Switch error={error} helperText={error?.message} fullWidth size="small" {...field} {...props}/>}
      />
      <Typography component={"label"} variant={"body1"} fontWeight={"bold"}>{children}</Typography>
    </>
  );
};
export default Checkbox;
