import React, { useState }  from 'react'
import ky from 'ky';
import {Box} from '@mui/material'
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
const Form = ({children,validation,url}) => {
  const [submited, setSubmited] = useState(false);
  const methods = useForm({
    resolver: yupResolver(validation)
  });
  const onSubmit = async data => {
    try {
      await ky.post(url, {json:data})

    }
    catch (e) {
      console.error(e)
    }
    setSubmited(true)
  }
  if (!submited) {
    return (
      <FormProvider {...methods} >
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)} >
        {children}
      </Box>
      </FormProvider>
    )
  }
  else if (submited){
   return (<h1>Danke für Ihre Anfrage !</h1>)
  }

}
export default Form
